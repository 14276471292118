import {Component, Input} from '@angular/core';
import {MapService} from '@services/map/map.service';
import {TranslationPathConstants} from '@utils/constants/translation-path.constants';
import {IVehicleDataGroup} from '@utils/interfaces/key-readout/vehicle-data-group.interface';

/**
 * This component cointains information regarding the vehicle data
 */
@Component({
  selector: 'app-vehicle-data',
  templateUrl: './vehicle-data.component.html',
  styleUrls: ['../../key-data.component.scss']
})
export class VehicleDataComponent {
  // vehicle data information
  @Input() groupServiceKey?: IVehicleDataGroup;
  // highlight mileage related text
  @Input() highlightText?: boolean;


  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/car-24.svg';
  // data path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.vehicleData;

  /**
   * Creates an instance of VehicleDataComponent.
   *
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   */
  constructor(
    public mapService: MapService
  ) {
  }

  getServiceSchedulingActivated(serviceSchedulingActivated): string {
    if (serviceSchedulingActivated === true)
      return this.mapService.mapFieldIdToPath('field-id-service-scheduling-activated.' + "yes", this.translationPath)
    else if (serviceSchedulingActivated === false) {
      return this.mapService.mapFieldIdToPath('field-id-service-scheduling-activated.' + "no", this.translationPath)
    } else if (serviceSchedulingActivated === "not capable") {
      return this.mapService.mapFieldIdToPath('field-id-service-scheduling-activated.' + "not-capable", this.translationPath)
    } else {
      return this.mapService.mapFieldIdToPath('field-id-service-scheduling-activated.' + "no-information", this.translationPath)
    }
  }
}

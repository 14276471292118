<app-icon-card [icon]="iconPath">
  <groupui-grid-row class="content-container">
    <!-- first col (vin and sales code) -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- vin -->
      <groupui-grid-row>
        <groupui-grid-col l="8" m="8" s="8" class="fieldName textWrapFloatLeft">
          {{ mapService.mapFieldIdToPath(groupServiceKey.vehicleData.data.vin?.fieldId, translationPath) | placeholder }}
        </groupui-grid-col>
        <groupui-grid-col l="4" m="4" s="4" class="value">
          {{ groupServiceKey.vehicleData.data.vin?.value | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- sales code -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(groupServiceKey.vehicleData.data.salesCode?.fieldId, translationPath)"
        [value]="groupServiceKey.vehicleData.data.salesCode?.value"></app-description-value-cell>
      <!-- Service Scheduling Activated -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath('field-id-service-scheduling-activated.' + 'label', translationPath)"
        [value]="getServiceSchedulingActivated(groupServiceKey.serviceKeyData.serviceSchedulingActivated?.value)">
      </app-description-value-cell>
    </groupui-grid-col>

    <!-- second col (delivery date and production date) -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- delivery date -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(groupServiceKey.vehicleData.data.deliveryDate?.fieldId, translationPath)"
        [value]="groupServiceKey.vehicleData.data.deliveryDate?.value">
      </app-description-value-cell>
      <!-- production date -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(groupServiceKey.vehicleData.data.productionDate?.fieldId, translationPath)"
        [value]="groupServiceKey.vehicleData.data.productionDate?.value">
      </app-description-value-cell>
      <!-- Enrollment status -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath('field-id-enrollment-status.title', translationPath)"
        [value]="mapService.mapFieldIdToPath('field-id-enrollment-status.' + (groupServiceKey.serviceKeyData?.enrollmentStatus?.value + ''), translationPath)"
        [ngClass]="{'highlight-text': highlightText}">
      </app-description-value-cell>
    </groupui-grid-col>
  </groupui-grid-row>
</app-icon-card>

<!-- if there is no key data, but the read-out process was successful, use the unhappy path -->
<div [class.hidden]="loadingIndicatorService.loading$ | async" *ngIf="isKeyReadoutSuccessful(); else unhappyPath">
  <div *ngIf="groupServiceKey;">
    <!-- Title of the key data page containing the brand code the model and year of manufacture -->
    <div class="headline">
      <!-- title -->
      <groupui-headline heading="h2">
        <img class="brand" src="{{displayBrandLogo(groupServiceKey.vehicleData.data.brandCode.value)}}" alt=""/> |
        {{ groupServiceKey.vehicleData.data.modelName.value }} | {{ groupServiceKey.vehicleData.data.modelYear.value }}
      </groupui-headline>
      <groupui-button-group>
        <!-- download button -->
        <groupui-button tabindex="0" alignment="center" size="s" type="button" variant="secondary"
                        (click)="onDownload()">
          <img src="assets/icons/key-data/download-24.svg" alt="download key data"/>
        </groupui-button>
        <!-- print button -->
        <groupui-button tabindex="0" alignment="center" size="s" type="button" variant="secondary" (click)="onPrint()">
          <img src="assets/icons/key-data/print-24.svg" alt="print key data"/>
        </groupui-button>
      </groupui-button-group>
    </div>
    <div class="headline">
      <!-- subtitle contains the timestamp of data storage and a status of this timestamp -->
      <groupui-headline heading="h6" [ngClass]="getDataStorageStatusClass(groupServiceKey.serviceKeyData.timestamp)">
        {{ 'key-data.key-data-subtitle' | translate }} {{ groupServiceKey.serviceKeyData.timestamp | date: 'dd-MM-YYYY HH:mm' }}
        {{ getDataStorageInfoText(groupServiceKey.serviceKeyData.timestamp) }}
      </groupui-headline>
      <!-- hide details toggle -->
      <div class="missing-fields">
        <groupui-switch tabindex="0" checked="{{isToggleActive}}" (groupuiChange)="toggleMissingData()"
                        (keydown)="onEnterPressedToggleMissingData($event)"
                        style="transform: scale(0.65);"></groupui-switch>
        <!-- please add translation -->
        <p>
          {{ 'key-data.show-empty-fields' | translate }}
        </p>
      </div>
    </div>
    <br>
    <!-- vehicle data information -->
    <app-vehicle-data [groupServiceKey]="groupServiceKey"></app-vehicle-data>
    <!-- vehicle color information (interior and exterior) -->
    <app-color-information [vehicleColor]="groupServiceKey.vehicleData.color"></app-color-information>
    <!-- key data -->
    <!-- distance information -->
    <app-distances [mileage]="groupServiceKey.serviceKeyData.mileage"
                   [mileageNote]="groupServiceKey.serviceKeyData?.mileageNote"
                   [mileageTimestamp]="groupServiceKey.serviceKeyData.mileageTimestamp"
                   [serviceInterval]="groupServiceKey.serviceKeyData.serviceIntervalDisplay">
    </app-distances>
    <!-- fluid levels -->
    <app-fluid-levels [oilNorm]="groupServiceKey.serviceKeyData.oilNorm"
                      [remainingRangesOfDrives]="groupServiceKey.serviceKeyData.remainingRangesOfDrives"
                      [fluidLevels]="groupServiceKey.serviceKeyData.fluidLevels"
                      [adBlue]="groupServiceKey.serviceKeyData.adBlue">
    </app-fluid-levels>
    <!-- active and historical warnings -->
    <app-warnings [activeWarnings]="groupServiceKey.serviceKeyData.warningMessagesActive"
                  [historicalWarnings]="groupServiceKey.serviceKeyData.warningMessagesHistory">
    </app-warnings>
    <!-- battery information -->
    <app-battery-information [batteryChargeLevel]="groupServiceKey.serviceKeyData.batteryChargeLevel"
                             [electricVehicleInformation]="groupServiceKey.serviceKeyData.electricVehicleInformation">
    </app-battery-information>
    <!-- diagnostic trouble code -->
    <app-diagnostic-trouble-code [troubleCodes]="groupServiceKey.serviceKeyData.diagnosticTroubleCodes">
    </app-diagnostic-trouble-code>
    <!-- energy consumer and hints  -->
    <app-energy-consumer-hints [energyConsumers]="groupServiceKey.serviceKeyData.energyConsumers"
                               [energyHints]="groupServiceKey.serviceKeyData.energyHints">
    </app-energy-consumer-hints>
    <!-- emission -->
    <app-emission *ngIf="(unit | async) != 'Miles'" [emission]="groupServiceKey.emissionData"></app-emission>  </div>
</div>

<!-- Unhappy Path if no data are available -->
<ng-template #unhappyPath>
  <div *ngIf="!isLoading">
    <img src="assets/icons/key-reader/tooltip-filled-48.svg" alt=""/>
    <div class="unhappy-path">
      <p>{{ 'key-data.noKeyData' | translate }}</p>
      <br>
    </div>
  </div>
</ng-template>

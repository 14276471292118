<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <!-- First Row: Mileage and Mileage Timestamp -->
  <groupui-grid-row class="content-container">
    <!-- First Column: Mileage -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(mileage?.fieldId, translationPath)"
        [value]="determineMileageValue()"
        [unit]="mileage?.unit">
      </app-description-value-cell>
    </groupui-grid-col>
    <!-- Second Column: Mileage Timestamp -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(mileageTimestamp?.fieldId, translationPath)"
        [value]="mileageTimestamp?.value ? (mileageTimestamp?.value.toString() | date: 'dd-MM-YYYY HH:mm') : ''"
        [ngClass]="{'highlight-text': highlightText}">
        >
      </app-description-value-cell>
    </groupui-grid-col>
  </groupui-grid-row>

  <!-- Second Row: Mileage Info and Empty Space -->
  <groupui-grid-row class="content-container">
    <!-- First Column: Mileage Info -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(mileageInfo?.fieldId, translationPath)"
        [value]="mileageInfo?.note"
        [ngClass]="{'highlight-text': highlightText}">
        >
      </app-description-value-cell>
    </groupui-grid-col>
    <!-- Second Column: Empty Space -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <div class="empty-space">&nbsp;</div>
    </groupui-grid-col>
  </groupui-grid-row>

  <!-- Third Row: Inspection Distance and Oil Distance -->
  <groupui-grid-row class="content-container">
    <!-- First Column: Inspection Distance -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.inspectionDistance?.fieldId, translationPath)"
        [value]="serviceInterval?.inspectionDistance?.value"
        [unit]="serviceInterval?.inspectionDistance?.unit">
      </app-description-value-cell>
    </groupui-grid-col>
    <!-- Second Column: Oil Distance -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.oilDistance?.fieldId, translationPath)"
        [value]="serviceInterval?.oilDistance?.value"
        [unit]="serviceInterval?.oilDistance?.unit">
      </app-description-value-cell>
    </groupui-grid-col>
  </groupui-grid-row>

  <!-- Fourth Row: Inspection Time and Oil Time -->
  <groupui-grid-row class="content-container">
    <!-- First Column: Inspection Time -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.inspectionTime?.fieldId, translationPath)"
        [value]="serviceInterval?.inspectionTime?.value"
        [unit]="serviceInterval?.inspectionTime?.unit">
      </app-description-value-cell>
    </groupui-grid-col>
    <!-- Second Column: Oil Time -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.oilTime?.fieldId, translationPath)"
        [value]="serviceInterval?.oilTime?.value"
        [unit]="serviceInterval?.oilTime?.unit">
      </app-description-value-cell>
    </groupui-grid-col>
  </groupui-grid-row>
</app-icon-card>
